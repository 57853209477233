import "styles/pages/page-north-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "components/Breadcrumbs";

const NorthMonumentsPage = ({ pageContext }) => {
	const pageNMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/northmonuments-bg-main.jpg").default
				}
			/>

			<Breadcrumbs
				prevLocationLink="/zabytki"
				prevLocationLabel="Zabytki"
				currentLocationLabel="Zabytki Północ"
			/>
			<section className="page-monuments">
				<div className="container">
					<h2 className="section-title">
						{pageNMonuments.section1Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section1Subtitle}
								desc={pageNMonuments.section1Text}
								addres={pageNMonuments.section1Address}
								hours={pageNMonuments.section1Hours}
								phone={pageNMonuments.section1Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNMonuments.section1Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wat-phra">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNMonuments.section2Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section2Title}
								desc={pageNMonuments.section2Text}
								addres={pageNMonuments.section2Address}
								hours={pageNMonuments.section2Hours}
								phone={pageNMonuments.section2Phone}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="ratchaphuruek">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section3Title}
								desc={pageNMonuments.section3Text}
								addres={pageNMonuments.section3Address}
								hours={pageNMonuments.section3Hours}
								phone={pageNMonuments.section3Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNMonuments.section3Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="doi-suthep">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNMonuments.section4Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section4Title}
								desc={pageNMonuments.section4Text}
								addres={pageNMonuments.section4Address}
								hours={pageNMonuments.section4Hours}
								phone={pageNMonuments.section4Phone}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="chiang-mai">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section5Title}
								desc={pageNMonuments.section5Text}
								addres={pageNMonuments.section5Address}
								hours={pageNMonuments.section5Hours}
								phone={pageNMonuments.section5Phone}
							/>
						</div>
						<div className="col-xl-4 offset-xl-2 text-center">
							<img
								src={pageNMonuments.section5Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="sankhampang">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNMonuments.section6Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section6Title}
								desc={pageNMonuments.section6Text}
								addres={pageNMonuments.section6Address}
								hours={pageNMonuments.section6Hours}
								phone={pageNMonuments.section6Phone}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="sang">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section7Title}
								desc={pageNMonuments.section7Text}
								addres={pageNMonuments.section7Text}
								hours={pageNMonuments.section7Hours}
								phone={pageNMonuments.section7Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNMonuments.section7Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="heavenly">
				<div className="container">
					<h2 className="section-title">
						{pageNMonuments.section8Title}
					</h2>
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-5 text-center">
							<img
								src={pageNMonuments.section8Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-2">
							<TextWithSymbol
								title={pageNMonuments.section8Subtitle}
								desc={pageNMonuments.section8Text}
								hours={pageNMonuments.section8Hours}
								phone={pageNMonuments.section8Phone}
								addres={pageNMonuments.section8Address}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wat-rong">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section9Title}
								desc={pageNMonuments.section9Text}
								phone={pageNMonuments.section9Phone}
								hours={pageNMonuments.section9Hours}
								addres={pageNMonuments.section9Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNMonuments.section9Img?.sourceUrl}
								alt=""
								className="img-fluid wat-rong__img"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="singha-park">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNMonuments.section10Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section10Title}
								desc={pageNMonuments.section10Text}
								phone={pageNMonuments.section10Phone}
								hours={pageNMonuments.section10Hours}
								addres={pageNMonuments.section10Address}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="ban-pang">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section11Title}
								desc={pageNMonuments.section11Text}
								addres={pageNMonuments.section11Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageNMonuments.section11Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="chiang-saen">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageNMonuments.section12Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageNMonuments.section12Title}
								desc={pageNMonuments.section12Text}
								arrive={pageNMonuments.section12Transport}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NorthMonumentsPage;
